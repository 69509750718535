/* -----------------------------------
 * Themes */

 .themes {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 6vmin 6vmin 12vmin;
    z-index: 1;
}

.themes header {
    margin: 0 auto 4rem;
    text-align: center;
}

.themes footer {
    margin: 0 auto;
    text-align: center;
}

.themes header h1,
.themes header h2 {
    margin: 0 0 1rem;
}

.themes footer h3 {
    margin: 0 0 1rem;
}

.themes .themes-preview {
    cursor: pointer;
    position: relative;
    width: 100%;
    min-width: 200px;
    height: auto;
    text-decoration: none;
}

.themes .themes-item {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 2px 5px 15px 0 rgba(0,0,0,.08);
    transition: all .4s ease;
    will-change: box-shadow;
}

.themes .themes-item:hover {
    box-shadow: 0px 10px 20px 0 rgba(0,0,0,.15);
}

.themes .themes-item::after {
    content: "Preview";
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    outline: none;
    color: var(--color-white);
    border: var(--color-base) 1px solid;
    background: var(--color-base);
    text-decoration: none !important;
    user-select: none;
    border-radius: 0.8rem;
    font-size: 1.5rem;
    line-height: 1em;
    min-height: 38px;
    padding: 1rem 1.5rem;
    transform: translate(-50%, 6px);
    transition: opacity 200ms ease-out, transform 150ms ease-out;

    -webkit-font-smoothing: subpixel-antialiased;
}

.themes .themes-item:hover::after {
    opacity: 1;
    transform: translate(-50%, 0);
}

.themes .themes-image::after {
    content: "";
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(254, 254, 254, 0.6);
    opacity: 0;
    transition: opacity 200ms ease-out;
}

.themes .themes-item:hover .themes-image::after {
    opacity: 1;
}

.themes .themes-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1.75rem 0 0.75rem;
}

.themes .themes-info h4 {
    margin: 0;
    color: var(--color-base);
    font-size: 2rem;
    font-weight: 600;
}

.themes .themes-info p {
    color: var(--color-midgrey);
    font-size: 1.2rem;
    font-weight: 500;
    margin: 2px 0 0;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}

.themes .themes-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5.6vmin 4.8vmin;
    margin: 0 auto 12rem;
    max-width: 1400px;
}

.themes footer {
    padding: 96px 0 0;
    background: transparent;
    background-image: url(/images/themes/search.svg);
    background-repeat: no-repeat;
    background-size: 64px;
    background-position: top center;
}

.themes p {
    font-size: 1.9rem;
    line-height: 1.5em;
    margin: 0.5rem 0 2rem;
}

.modal {
    opacity: 0;
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.modal.is-open {
    opacity: 1;
    z-index: 99;
}

.modal .modal-gap {
    height: 32px;
    width: 100%;
    background: transparent;
    cursor: pointer;
}

.modal .modal-container {
    flex: 1;
    background: linear-gradient(0deg, #efefef, #fff);
    box-sizing: border-box;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 50px rgb(0 0 0 / 20%);
}

.modal .modal-contents {
    position: relative;
    width: 80%;
    max-width: 1400px;
    height: 100%;
    margin: 96px 0 0 0;
    border: 0 none;
    z-index: 98;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(64px);
    transition: 225ms transform 100ms ease-out, opacity 125ms linear;
    opacity: 0;
}

.modal.is-open .modal-contents {
    transform: translateY(0);
    opacity: 1;
}

.modal .modal-frame {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
    z-index: 98;
    box-shadow: 0 3px 20px rgb(0 0 0 / 8%);
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal .modal-frame.is-mobile {
    background-color: var(--color-white);
    border-radius: 40px;
    height: calc(100vh - 8vmin - 80px);
    margin: 0 auto;
    max-height: 800px;
    padding: 10px;
    width: 400px;
    overflow: hidden;
}

.modal .modal-back:hover {
    background: transparent;
    transform: translateY(3px);
}

.modal .modal-frame iframe {
    flex: 1;
    margin: 0;
    padding: 0;
    border: 0 none;
}

.modal .modal-frame.is-mobile iframe {
    border-radius: 32px;
}

.modal .modal-details {
    font-size: 1.85rem;
}

.modal .modal-details a {
    font-weight: 600;
    color: var(--color-midgrey);
    text-decoration: none;
}

.modal .modal-details b {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0;
    color: var(--color-base);
}

.modal .modal-header {
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    z-index: 100;
    min-height: 84px;
    width: 100%;
    background: var(--color-white);
    padding: 0 min(30px,6vmin);
    border-bottom: 1px solid var(--color-border);
}

.modal .modal-header-column {
    align-items: center;
    justify-self: start;
}

.modal .modal-header-column:first-child {
    justify-self: flex-start;
    grid-column: 1;
    grid-row: 1;
}

.modal .modal-header-column.theme-select-wrapper {
    display: flex;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    margin-left: 135px;
}

.modal .modal-header-column.preview-mode {
    justify-self: center;
}

.modal .modal-header-column:last-child {
    justify-self: flex-end;
}

.modal #preview-title {
    margin: 0 0 0 2rem;
    padding: 0;
    color: color-mod(var(--color-base) l(+3%));
    font-size: 1.55rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1em;
    display: inline-block;
}

.modal .spacer {
    display: inline-block;
    margin: 0 1rem;
}

.modal .theme-dropdown {
    display: block;
    position: relative;
    margin-left: 0;
}

.modal .theme-select {
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 0.6rem;
    background-color: var(--color-white);
    padding: 0.8rem 2.8rem 0.8rem 0.4rem;
    text-align: left;
    font-weight: 600;
    color: color-mod(var(--color-base) l(+3%));
    font-size: 1.55rem;
}

.modal .theme-select-label {
    display: flex;
    align-items: center;
}

.modal .theme-color {
    display: inline-block;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 100%;
    flex-shrink: 0;
}

.modal .theme-name {
    margin-left: 0.8rem;
    padding-bottom: 0.4rem;
    padding-top: 0.4rem;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: color-mod(var(--color-base) l(+3%));
}

.modal .theme-select-icon {
    position: absolute;
    display: flex;
    align-items: center;
    padding-right: 0.8rem;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
}

.modal .theme-dropdown-list {
    position: absolute;
    z-index: 10;
    margin: 0 0 0 -16px;
    min-width: 160px;
    width: 100%;
    overflow: auto;
    border-radius: 0.6rem;
    background-color: var(--color-white);
    padding: 1rem 0;
    font-size: 1.8rem;
    font-weight: 600;
    opacity: 0;
    border: 1px solid rgb(0 0 0 / .05);
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
    transition-property: opacity, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transform: translateY(-0.4rem);
}

.modal .theme-dropdown-list.is-open {
    opacity: 1;
    transform: translateY(0);
}

.modal .theme-dropdown-list:focus {
    outline: none;
}

.modal .theme-dropdown-list .theme-item {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 0.6rem 2rem;
    margin: 0;
}

.modal .theme-dropdown-list .theme-item:hover {
    opacity: 0.9;
}

.modal .theme-dropdown-list .theme-name {
    font-size: 1.55rem;
    padding: 0;
}

@media only screen and (max-width: 950px) {
    .modal .modal-header[data-theme='source'] {
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 84px 70px;
        min-height: calc(84px + 70px);
    }

    .modal .modal-header-column:first-of-type,
    .modal .modal-header-column:last-of-type {
        align-self: center;
    }

    .modal .modal-header-column.theme-select-wrapper {
        margin-left: 0;
        grid-row: 2;
        grid-column: 2;
        justify-self: center;
        position: relative;
        padding: calc((70px - 48px) / 2) 0;
    }

    .modal .modal-header-column.theme-select-wrapper::before {
        position: absolute;
        content: "";
        width: 200vw;
        height: 1px;
        background: var(--color-border);
        top: 0;
        left: -100vw;
        z-index: 11;
    }

    .modal #preview-title {
        margin-left: 0.8rem;
    }
}

@media only screen and (max-width: 650px) {
    .modal .modal-header,
    .modal .modal-header[data-theme='source'] {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
    }

    .modal .modal-header-column.theme-select-wrapper {
        grid-column: 1 / span 2;
    }

    .modal .modal-header-column.preview-mode {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .modal .modal-header[data-theme='source'] {
        padding: 0 4vw;
    }
}

.modal .modal-header-selector {
    background-color: var(--color-offwhite);
    border-radius: 4px;
    display: flex;
    height: 36px;
    padding: 2px;
}

.modal .modal-header-selector img {
    width: 20px;
}

.modal .modal-header-selector button {
    background-color: transparent;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
    align-items: center;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    width: 70px;
    text-indent: -9999em;
    overflow: hidden;
}

.modal .modal-header-selector button.is-active {
    background-color: #fff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}

.modal .modal-header-selector-desktop {
    background-image: url(/images/themes/desktop.svg);
}

.modal .modal-header-selector-mobile {
    background-image: url(/images/themes/mobile.svg);
}

.modal .modal-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1700px;
    padding: 16px 0 48px;
    background: transparent;
    z-index: 100;
    font-weight: 500;
    color: #394047;
}

.modal .modal-loader {
    opacity: 1;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-white);
    transition: opacity 275ms ease-out, z-index 275ms step-start;
}

.modal .modal-loader.is-loaded {
    opacity: 0;
    z-index: -1;
    transition-timing-function: ease-out, step-end;
}

@media only screen and (max-width: 800px) {
    .themes .themes-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 64px;
        margin: 0 auto 12rem;
    }
}

@media only screen and (max-width: 1044px) {
    .modal .modal-contents {
        max-width: 95%;
        width: 95%;
    }
}

@media only screen and (max-width: 1200px) {
    .modal .modal-contents {
        width: 100%;
        max-width: 100%;
        margin: 0;
        transform: translateY(0);
    }

    .modal .modal-contents .browser {
        display: none;
    }

    .modal .modal-frame {
        border-radius: 0;
    }
}

@media only screen and (min-width: 1200px) and (max-height: 1100px) {
    .modal .modal-contents {
        margin: 32px 0 0 0;
    }
}

.browser {
    background-color: var(--color-white);
    border-radius: 5px 5px 0 0;
    height: 32px;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.browser-dot {
    background: var(--color-lightgrey);
    border-radius: 50%;
    height: 6px;
    width: 6px;
    margin: 0 4px;
}

.modal .modal-frame.is-mobile .browser {
    display: none;
}

.no-scroll {
    overflow: hidden;
}
