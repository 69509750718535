@import "components/vars.css";
@import "components/global.css";
@import "components/404.css";
@import "components/themes.css";

/* --------------- Added by Aileen ----------------- */
.form-error {
    margin: 16px 0 0;
    font-weight: 500;
}

.form-error p, p.form-error {
    margin: 0;
    color: var(--color-red);
    text-align: center;
}

.form-error.warning p {
    color: color-mod(var(--color-green) l(-7%));
}

.form-error.warning p a {
    color: color-mod(var(--color-green) l(-7%));
    text-decoration: underline;
}

p.inline-error {
    margin: 0;
    color: var(--color-red);
}

/* Hide vertical scrollbar, but still allow scrolling */
html::-webkit-scrollbar, body::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

html, body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* -------------------- End: Added by Aileen -------------------- */

.viewport {
    width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.mainhead {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.home-link svg {
    height: 40px;
}

.home-link img {
    height: 55px;
    width: auto;
    filter: invert(0.9);
}

.home-link .pro-logo-svg {
    height: 26px;
    margin: 0 0 2px 5px;
}

.gh-form {
    position: relative;
    display: block;
    margin: 0 0 40px;
    padding: 40px 20px;
    width: 580px;
}

@media (max-width: 580px) {
    .gh-form {
        display: block;
        width: 100%;
        max-width: 100%;
    }
}

.gh-form header {
    margin: 0 0 40px 0;
    text-align: center;
}

.gh-form header p {
    margin: 0.4em 0 0 0;
    font-size: 1.9rem;
    line-height: 1.4em;
}
@media (max-width: 500px) {
   .gh-form header p {
       font-size: 1.5rem;
   }
}

.gh-form footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    margin: 36px 0 0 0;
    line-height: 1.4em;
}

.gh-btn {
    display: inline-block;
    min-height: 44px;
    padding: 1.6rem 2rem;
    outline: none;
    font-size: 1.8rem;
    line-height: 1em;
    color: #fff;
    border: var(--color-base) 1px solid;
    background: var(--color-base);
    text-decoration: none !important;
    user-select: none;
    border-radius: 0.8rem;
    transition: background 0.3s ease;
    white-space: nowrap;

    -webkit-font-smoothing: subpixel-antialiased;
}
.gh-btn:hover {
    background: #000;
    transition: background 0.3s ease;
}

.gh-btn-block {
    display: block;
    width: 100%;
}

.gh-btn.disabled,
.gh-btn[disabled],
fieldset[disabled] .gh-btn {
    box-shadow: none;
    opacity: 1;
    cursor: not-allowed;
    pointer-events: none;
}

.gh-btn.disabled:not(.spinner),
.gh-btn[disabled]:not(.spinner),
fieldset[disabled].gh-btn :not(.spinner) {
    opacity: 0.8;
}
.gh-btn-green {
    background: var(--color-green);
    border-color: var(--color-green);
}
.gh-btn-green:hover {
    background: var(--color-green);
}

.gh-btn-text {
    height: 33px;
    margin-right: 8px;
    padding: 5px 12px 6px;
    outline: none;
    border: none;
    color: #394047;
    font-size: 1.35rem;
    font-weight: 500;
    letter-spacing: .2px;
    white-space: nowrap;
    background: #fff;
    border-radius: 3px;
    transition: all .25s ease;
    transition-property: color,border-
}

.gh-btn-text svg {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.5em;
    padding-top: 2px;
    fill: #394047;
}

.gh-btn-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    text-indent: -9999em;
    overflow: hidden;
    background: transparent;
    background-image: url(/images/themes/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    color: #ffffff;
}

.gh-btn-close svg path {
    stroke: #ffffff;
}

.gh-btn-back {
    display: inline-block;
    padding: 0;
    margin: 0;
    color: var(--color-midgrey);
    font-size: 1.55rem;
    font-weight: 600;
    line-height: 1em;
    min-height: 44px;
    outline: none;
    transition: color 200ms ease-out;
    white-space: nowrap;
}

.gh-btn-back:hover {
    color: var(--color-middarkgrey);
}

.gh-btn-sm {
    min-height: 38px;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    line-height: 1em;
}

/*
/* Loading Button Spinner
/* ---------------------------------------------------------- */
/*

Usage: Swap out button>span text with HTML

<a class="gh-btn gh-btn-blue">
    <span>
        <div class="gh-spinner"></div>
    </span>
</div>

*/

.gh-spinner-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-right: -8px;
}

.gh-spinner {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: rgba(0,0,0,0.2) solid 4px;
    border-radius: 100%;
    vertical-align: text-bottom;
    animation: spin 1s linear infinite;
}

.gh-spinner:before {
    content: "";
    display: block;
    margin-top: 9px;
    width: 4px;
    height: 4px;
    background: rgba(0,0,0,0.6);
    border-radius: 100%;
}

.gh-btn-green .gh-spinner,
.gh-btn-block .gh-spinner {
    border-color: rgba(255,255,255,0.2);
}

.gh-btn-green .gh-spinner:before,
.gh-btn-block .gh-spinner:before {
    background: rgba(255,255,255,0.6);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.gh-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-bottom: 2.5rem;
}

.gh-input-group p {
    display: block;
    margin: 0.3em 0 0 0;
    font-size: 0.9em;
}

.gh-input-group + .gh-btn {
    margin-top: 40px;
}

.gh-input-group-two {
    justify-content: space-between;
    display: flex;
    flex-wrap: nowrap;
}

.gh-input-group-two > div {
    flex: 1 1 auto;
}

.gh-input-group-two > div + div {
    margin-left: 2vmin;
    flex: 0 0 90px;
}

.gh-input,
.gh-select,
select {
    display: block;
    padding: 1.5rem 2rem;
    min-height: 44px;
    width: 100%;
    border: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    color: var(--color-base);
    font-size: 1.8rem;
    line-height: 1em;
    font-weight: normal;
    user-select: text;
    border-radius: 0.8rem;
    background: #fff;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.gh-select,
select {
    cursor: pointer;
}

::-webkit-input-placeholder {
    color: color-mod(var(--color-midgrey) l(+15%));
}

:-ms-input-placeholder {
    color: color-mod(var(--color-midgrey) l(+15%));
}

textarea {
    min-width: 250px;
    max-width: 500px;
    width: 100%;
    height: 6.5em;
    line-height: 1.5;
    user-select: text;
    resize: vertical;
}

textarea.gh-input {
    line-height: 1.5em;
    max-width: 100%;
    resize: none;
}

.gh-input:focus,
.gh-input.focus,
.gh-select:focus,
select:focus,
textarea:focus {
    outline: 0;
    border-color: #87D20D;
    box-shadow: 0px 0px 0px 3px rgba(26, 170, 96, 0.15);
}
a:not([class]):focus {
    box-shadow: 0px 2px 0px 0 #87D20D;
}

/* Only for site as Stripe billing was iFrame, wanted something more visible for set up */
#signup-form-site .gh-input.disabled,
#signup-form-site .gh-input[disabled] {
    opacity: 0.5;
    cursor: default;
}

.gh-input.error {
    border-color: var(--color-red);
    box-shadow: 0px 0px 0px 3px color-mod(var(--color-red) a(0.15));
}


.gh-input:-webkit-autofill {
    transition: background-color 100000000s;

    -webkit-text-fill-color: color-mod(var(--color-midgrey) l(-5%));
    -webkit-animation: 1ms void-animation-out;
}

.gh-input.stripe-autofill {
    background-color: #fff!important;
}

.gh-input.color-picker-wrapper-outer {
    width: 90px;
    height: 54px;
    padding: .9rem 1rem;
    margin: 0;
    overflow: hidden;
    position: relative;
}

.color-picker-wrapper {
    height: calc(54px - .9rem - .9rem - 2px);
    width: calc(90px - 1rem -1rem -2px);
    position: absolute;
    overflow: hidden;
    top: .9rem;
    left: 1rem;
    border-radius: .3rem;
}

.gh-input.color-picker {
    position: absolute;
    top: -10px;
    left: -10px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100px;
    height: 55px;
    cursor: pointer;
}

.verification-modal {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 40px;
    right: 20px;
    left: 20px;
    min-height: 100%;
    padding: 35px 40px 45px;
    background: #fff;
    border-radius: 15px;
}

.verification-modal.is-open {
    display: flex;
}

.verification-modal h2 {
    margin: 0 0 0.5em;
}

@media (min-width: 551px) {
    .verification-modal p {
        font-size: 1.6rem;
    }
}

#signup-form-email.gh-form footer p {
    max-width: 400px;
    text-wrap: pretty;
}

#signup-form-billing footer {
    min-height: 80px;
}

.gh-form.is-disabled label,
.gh-form.is-disabled input,
.gh-form.is-disabled #signup-form-cc {
    opacity: 0.5;
}

.forgot-site-link {
    position: absolute;
    right: 0;
    font-size: 0.9em;
    color: var(--color-base);
    opacity: 0.5;
    text-decoration: none;
}
.forgot-site-link:hover {
    text-decoration: underline;
    opacity: 1;
}

.recent-sites {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    width: 100%;
    max-height: 30vh;
    margin: 0.3rem 0 0 0;
    overflow-y: scroll;
}

.recent-site {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.8rem 2rem;
    width: 100%;
    overflow: hidden;
    border: color-mod(var(--color-lightgrey) l(+7%)) 1px solid;
    color: var(--color-base);
    font-size: 1.8rem;
    text-decoration: none;
    border-radius: 0.8rem;
    background: #fff;
    transition: all 0.3s ease;
}
.recent-site:hover {
    border-color: color-mod(var(--color-lightgrey) l(-3%));
}

.recent-site .offline {
    position: relative;
}

/* Badge is taken from ghost.org .gh-badge global.css */
.offline-badge {
    position: absolute;
    margin: 0 0 0 7px;
    display: inline-block;
    padding: 0.3em 0.3em 0.2em 0.3em;
    color: #fff;
    font-size: 0.7em;
    line-height: 1em;
    font-weight: 600;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
    white-space: nowrap;
    user-select: none;
    background: linear-gradient(
        color-mod(var(--color-purple) whiteness(+10%)),
        color-mod(var(--color-purple) blackness(+4%))
    );
    border: color-mod(var(--color-purple) blackness(+8%)) 1px solid;
    border-radius: 3px;
    box-shadow:
        rgba(0,0,0,0.15) 0 1px 3px,
        rgba(0,0,0,0.1) 0 0 0 1px inset
    ;
}

.recent-site img {
    height: 40px;
    width: 40px;
    margin: 0 1.5rem 0 0;
}

.recent-site > div {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin-right: 2rem;
}

.recent-site strong {
    line-height: 1.3em;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
 }

.recent-site span:not([class]) {
    font-size: 1.4rem;
    line-height: 1.3em;
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.recent-site svg {
    margin: 2px 0 0 0;
}

.signup-phrases {
    text-align: center;
    margin: 16px 0 0;
}

#signup-terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    margin: 40px 0 0;
    line-height: 1.4em;
    text-align: center;
}

html {
    background: var(--color-base);
}

.viewport {
    min-height: 100dvh;
    color-scheme: only light;
}

.viewport:has(#signup-container) {
    background: var(--color-base);
}

.gh-form-wrapper {
    position: relative;
    width: 100%;
    max-width: 584px;
    background-color: #fff;
    border-radius: 20px;
    transition: height 0.2s;
}

.gh-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: visible;
    position: static;
    top: auto;
    width: 580px;
    padding: 40px;
    margin: 0;
}

.gh-form.hidden {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.gh-form > *:not(header) {
    transition: opacity .2s, transform .2s;
}

.gh-form.hidden > *:not(header) {
    opacity: 0;
    transform: scale(0.98);
}

.gh-form header {
    margin: 0 0 36px 0;
}

.gh-form.create-account header {
    align-self: flex-start;
    text-align: left;
}

.gh-form header p {
    margin-top: 12px;
    font-size: 1.7rem;
    color: #747A81;
}

.gh-form footer p {
    max-width: 400px;
    margin: 0;
}

.gh-form footer a {
    color: var(--color-base);
    opacity: 0.8;
}

.gh-form footer p:last-child {
    margin-bottom: 0;
}

.gh-form footer > a {
    margin-top: 8px;
    opacity: 1;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: -0.007em;
}

.gh-form-content {
    width: 100%;
}

.gh-form-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.gh-input-group {
    margin-bottom: 2.4rem;
}

.gh-input-group:last-of-type {
    margin-bottom: 3.6rem;
}

.gh-form:not(:has(.gh-btn)) .gh-input-group:last-of-type {
    margin-bottom: 0;
}

.gh-input-group + .gh-btn {
    margin-top: 12px;
}

.gh-form button + button {
    margin-top: 16px;
}

.gh-input,
.gh-select,
select {
    padding: 1.3rem 1.6rem;
    border: 1px solid color-mod(var(--color-lightgrey) l(+15%));
    background: color-mod(var(--color-lightgrey) l(+15%));
}

.gh-input:focus,
.gh-input.focus,
.gh-select:focus,
select:focus,
textarea:focus {
    background-color: var(--white);
    border-color: var(--color-green);
    box-shadow: 0px 0px 0px 3px color-mod(var(--color-green) alpha(25%));
}

.gh-input.error {
    background-color: var(--white);
    border-color: var(--color-red);
    box-shadow: 0px 0px 0px 3px color-mod(var(--color-red) alpha(25%));
}

.signup-paragraphs {
    margin-bottom: 36px;
    font-size: 1.7rem;
    color: var(--color-base);
}

.signup-paragraphs p {
    margin-bottom: 1.2em;
}

.signup-paragraphs p:last-child {
    margin-bottom: 0;
}

.signup-paragraphs a {
    text-decoration: underline;
    text-underline-offset: 3.5px;
    text-decoration-skip: none;
    font-weight: 600;
}

.signup-features {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 8px;
    margin-bottom: 48px;
}

.signup-feature {
    display: flex;
    gap: 20px;
}

.signup-feature-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background-color: color-mod(var(--color-green) alpha(17%));
    border-radius: 50%;
}

.signup-feature-icon svg {
    color: var(--color-green);
}

.signup-feature-icon::after {
    content: "";
    position: absolute;
    top: 0;
    right: -4px;
    width: 16px;
    height: 16px;
}

.signup-feature-icon.check::after {
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_327_1630)'%3e%3ccircle cx='8' cy='8' r='8' fill='white'/%3e%3cpath d='M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C15.9873 3.58733 12.4127 0.0126667 8 0ZM12.8047 5.138L6.80467 11.138C6.67467 11.268 6.504 11.3333 6.33333 11.3333C6.16267 11.3333 5.992 11.268 5.862 11.138L3.19533 8.47133C2.93467 8.21067 2.93467 7.78933 3.19533 7.52867C3.456 7.268 3.87733 7.268 4.138 7.52867L6.33333 9.724L11.862 4.19533C12.1227 3.93467 12.544 3.93467 12.8047 4.19533C13.0653 4.456 13.0653 4.87733 12.8047 5.138Z' fill='black'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_327_1630'%3e%3crect width='16' height='16' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
}

.signup-feature-icon.cross::after {
    background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='8' fill='white'/%3e%3cpath d='M8 0C3.5815 0 0 3.5815 0 8C0 12.4185 3.5815 16 8 16C12.4185 16 16 12.4185 16 8C15.987 3.587 12.413 0.013 8 0ZM11.3535 10.6465C11.549 10.842 11.549 11.158 11.3535 11.3535C11.256 11.451 11.128 11.5 11 11.5C10.872 11.5 10.744 11.451 10.6465 11.3535L8 8.707L5.3535 11.3535C5.256 11.451 5.128 11.5 5 11.5C4.872 11.5 4.744 11.451 4.6465 11.3535C4.451 11.158 4.451 10.842 4.6465 10.6465L7.293 8L4.6465 5.3535C4.451 5.158 4.451 4.842 4.6465 4.6465C4.842 4.451 5.158 4.451 5.3535 4.6465L8 7.293L10.6465 4.6465C10.842 4.451 11.158 4.451 11.3535 4.6465C11.549 4.842 11.549 5.158 11.3535 5.3535L8.707 8L11.3535 10.6465Z' fill='%23212121'/%3e%3c/svg%3e");
}

.signup-feature p > strong {
    display: block;
    margin-bottom: 2px;
    font-size: 1.05em;
}

.signup-trial-choice {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: -12px;
    margin-bottom: 48px;
}

.signup-trial-choice label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 16px 16px;
    cursor: pointer;
    border: 1px solid #E5E9ED;
    border-radius: 8px;
    font-size: 1.7rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    transition: box-shadow 0.15s ease-in-out;
}

.signup-trial-choice label:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1.4px rgba(0, 0, 0, 0.035);
}

.signup-trial-choice input[type="radio"] {
    display: none;
}

.signup-trial-choice-radio {
    width: 24px;
    height: 24px;
    border: 2px solid #CED4D9;
    border-radius: 50%;
}

.signup-trial-choice .signup-trial-choice-radio {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-trial-choice label:has(input[type="radio"]:checked) .signup-trial-choice-radio {
    border-color: var(--color-base);
}

.signup-trial-choice .signup-trial-choice-radio::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #CED4D9;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0.8);
    transition: transform .15s ease-in-out;
}

.signup-trial-choice label:has(input[type="radio"]:checked) .signup-trial-choice-radio::after {
    background-color: var(--color-base);
    opacity: 1;
    transform: scale(1);
}

.signup-trial-choice label:hover .signup-trial-choice-radio::after {
    opacity: 1;
    transform: scale(1);
}

#signup-form-billing .gh-input-group:last-of-type {
    margin-bottom: 8px;
}

.signup-cc-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;
    border: 0;
    background-color: #fff;
}

.signup-cc-inputs > div:first-child {
    padding: 1.3rem 1.6rem;
    grid-column: 1 / -1;
}

#signup-form-billing .gh-btn {
    margin-top: 40px;
    min-height: 52px;
    background: linear-gradient(90deg, #60D20D, #009B7F);
    border: 0;
}

.marquee-wrapper {
    --gap: min(4vw,64px);
    position: fixed;
    inset: 0;
    display: flex;
    gap: var(--gap);
    height: 100%;
    padding-inline: var(--gap);
    background-color: var(--color-base);
}

.marquee-wrapper::before {
    content: "";
    position: fixed;
    inset: 0;
    z-index: 10;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.3) 53.6%, rgba(0, 0, 0, 0.65) 64.06%, #000000 83.25%, #000000 100%)
}

.marquee {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    width: 33.3333333333%;
}

.marquee-content {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
    animation: scroll 500s linear infinite;
}

.marquee-content img {
    aspect-ratio: 16 / 10;
    object-fit: cover;
    border-radius: 20px;
}

.marquee-quote {
    padding: 32px;
    border-radius: 20px;
    background-color: color-mod(var(--color-base) l(+8%));
    color: #CED4D9;
}

.marquee-quote p {
    margin-bottom: 1em;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.35;
    opacity: 0.7;
    text-wrap: pretty;
}

.marquee-quote-author {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: uppercase;
}

.marquee-quote-author img {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.marquee-quote-author span {
    opacity: 0.7;
}

.marquee-reverse .marquee-content {
    animation-direction: reverse;
    animation-delay: -30s;
}

.marquee:nth-child(3) .marquee-content {
    animation-delay: -60s;
    animation-duration: 200s;
}

@keyframes scroll {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(calc(-100% - var(--gap)));
    }
}

#mobile-form {
    visibility: hidden;
    position: absolute;
    top: 0;
}

#mobile-form .signup-paragraphs {
    margin-bottom: 24px;
}

#mobile-form .gh-input-group {
    margin-bottom: 0;
}

/* @media (max-width: 767px) {
    .gh-form:not(#mobile-form) {
        visibility: hidden;
        position: absolute;
        top: 0;
    }

    #mobile-form {
        visibility: visible;
        position: static;
    }
} */

@media (min-height: 800px) and (min-width: 560px) {
    .gh-form footer {
        position: fixed;
        bottom: 48px;
    }

     .gh-form.create-account footer a {
        color: #fff;
    }
}

@media (max-width: 560px) {
    .viewport, html {
        background: #fff;
    }

    .marquee-wrapper {
        display: none;
    }

    .signup-container {
        width: 100%;
    }

    .gh-form-wrapper {
        border-radius: 0;
        height: 100dvh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gh-form {
        min-width: auto;
        width: 100%;
        height: 100%;
        padding: 40px 28px;
    }

    .gh-form h1 {
        font-size: 2.4rem;
    }

    .gh-form header {
        margin: 0 0 30px;
    }

    label {
        font-size: 1.4rem;
    }

    .gh-input, .gh-select, select {
        padding: 1.2rem 1.4rem;
        font-size: 1.7rem;
    }

    .gh-btn,
    .gh-input-group + .gh-btn,
    #signup-form-billing .gh-btn {
        min-height: 48px;
        padding: 1.4rem 1.6rem;
        font-size: 1.7rem;
    }

    .gh-btn-link {
        font-size: 1.35rem;
    }

    .form-error {
        margin: 0 0 16px;
    }

    .form-error p, p.form-error {
        margin: 16px 0 16px 0;
        font-size: 1.35rem;
        font-weight: 500;
    }

    .signup-phrases {
        text-align: center;
        font-size: 1.35rem;
        line-height: 1.2;
        text-wrap: balance;
    }

    .gh-form-footer {
        margin-bottom: 24px;
    }

    .gh-form footer {
        margin: 28px 0 0;
        font-size: 1.35rem;
    }

    .signup-paragraphs {
        font-size: 1.5rem;
    }

    .signup-features {
        gap: 20px;
    }

    .signup-feature-icon {
        width: 36px;
        height: 36px;
    }

    .create-account .gh-btn {
        order: 2;
    }

    .create-account .form-error {
        order: 1;
    }

    .create-account footer {
        order: 3;
    }

    .card-details .gh-btn {
        order: 2;
    }

    .card-details .form-error {
        order: 3;
    }

    .card-details .signup-phrases {
        order: 3;
    }
}

@media (max-width: 991px) {
    .marquee {
        width: 50%;
    }

    .marquee:last-child {
        display: none;
    }
}

@media (max-width: 1200px) {
    .marquee-quote {
        display: none;
    }
}

@media (max-width: 1440px) {
    .marquee:nth-child(5) {
        display: none;
    }
}

@media (max-width: 2000px) {
    .marquee:nth-child(1) {
        display: none;
    }
}

@media (min-width: 1441px) and (max-width: 2000px) {
    .marquee:nth-child(4) {
        order: 5;
    }

    .marquee:nth-child(4) .marquee-content {
        animation-direction: normal;
    }

    .marquee:nth-child(5) {
        order: 4;
    }

    .marquee:nth-child(5) .marquee-content {
        animation-direction: reverse;
    }
}
